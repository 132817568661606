@media only screen and (max-width: 1200px) {
  .projects .content {
    padding: 3% 2% !important;
    width: 48%;
  }
  .projects .mockup {
    width: 60%;
    margin-left: -7%;
  }
}
@media only screen and (max-width: 991px) {
  .projects {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
  .projects:nth-child(even) {
    flex-direction: row;
    justify-content: center;
  }
  .projects .mockup {
    margin-left: 0;
    margin-right: 0 !important;
    width: 100%;
  }
  .projects .mockup .desktop {
    width: 100%;
  }
  .projects:nth-child(even) .mockup .desktop {
    transform: translateX(-100px);
  }
  .projects:nth-child(even) .content {
    transform: translateX(100px);
  }
  p {
    font-size: 16px;
    line-height: 1.5;
  }
  .projects .content {
    width: 100% !important;
  }
  .our-team .wrapper .row > * {
    padding: 0px 30px 0px 30px;
  }
  .projects .content {
    padding: 0 !important;
    margin-top: 15px;
  }
  .projects:nth-child(even) .mockup .mobile {
    left: auto;
    right: 7.15%;
  }
  .projects .mockup .mobile {
    transform: translateY(50px);
    right: 0 !important;
  }
  #header .nav .navbar {
    display: none;
  }
  #header .nav .navbar-burger {
    display: block;
    cursor: pointer;
  }
  /*Filter*/
  .filter {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .past-filter {
    position: relative;
    transform: none;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .past-filter .check {
    display: flex;
  }
}
@media only screen and (max-width: 740px) {
  #wrapper .container-md p br {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  #wrapper.first p {
    font-size: 16px;
  }
  .footer .footer-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 170px;
  }
  #services,
  #our-team {
    padding: 60px 0;
  }
  #services .row {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }

  .filter-menu li, .past-filter {
    padding: 8px 14px;
  }
}
