@media only screen and (max-width: 1200px) {
  .projects .content {
    width: 48%;
    padding: 3% 2% !important;
  }

  .projects .mockup {
    width: 60%;
    margin-left: -7%;
  }
}

@media only screen and (max-width: 991px) {
  .projects {
    width: 100%;
    flex-wrap: wrap;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
  }

  .projects:nth-child(2n) {
    flex-direction: row;
    justify-content: center;
  }

  .projects .mockup {
    width: 100%;
    margin-left: 0;
    margin-right: 0 !important;
  }

  .projects .mockup .desktop {
    width: 100%;
  }

  .projects:nth-child(2n) .mockup .desktop {
    transform: translateX(-100px);
  }

  .projects:nth-child(2n) .content {
    transform: translateX(100px);
  }

  p {
    font-size: 16px;
    line-height: 1.5;
  }

  .projects .content {
    width: 100% !important;
  }

  .our-team .wrapper .row > * {
    padding: 0 30px;
  }

  .projects .content {
    margin-top: 15px;
    padding: 0 !important;
  }

  .projects:nth-child(2n) .mockup .mobile {
    left: auto;
    right: 7.15%;
  }

  .projects .mockup .mobile {
    transform: translateY(50px);
    right: 0 !important;
  }

  #header .nav .navbar {
    display: none;
  }

  #header .nav .navbar-burger {
    cursor: pointer;
    display: block;
  }

  .filter {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .past-filter {
    align-items: center;
    gap: 4px;
    display: flex;
    position: relative;
    transform: none;
  }

  .past-filter .check {
    display: flex;
  }
}

@media only screen and (max-width: 740px) {
  #wrapper .container-md p br {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  #wrapper.first p {
    font-size: 16px;
  }

  .footer .footer-main {
    min-height: 170px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  #services, #our-team {
    padding: 60px 0;
  }

  #services .row {
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
  }

  .filter-menu li, .past-filter {
    padding: 8px 14px;
  }
}

/*# sourceMappingURL=index.48c46f09.css.map */
